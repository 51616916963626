
$header-top-height: 18px;

$rojo: #ce0000;
$rojo-claro: adjust_lightness($rojo,10);
$rojo-oscuro: adjust_lightness($rojo,-10);
$amarillo: #ffb900;
$amarillo-claro: adjust_lightness($amarillo,10);
$amarillo-oscuro: adjust_lightness($amarillo,-10);

$negro: #ffffff;

$image-path : '/images/';
$lo-image-path : '/images/' + 'layout/';
$lo-min-height: 650px;

// colores corporativos acs
$color-background : #ffffff;
$blanco: #ffffff;
$azul-acs-claro: #7baac6;
$azul-acs-medio: #266283;
$azul-acs-oscuro: #2b3f5e;

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); /* IE 9 */
    -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0); /* IE 9 */
    -webkit-transform: translate(-50%, 0); /* Chrome, Safari, Opera */
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50);
    -ms-transform: translate(0, -50%); /* IE 9 */
    -webkit-transform: translate(0, -50%); /* Chrome, Safari, Opera */
  }
}

.loader-gif{
  background: url('/images/common/ajax-loader.gif') center;
  margin: 1em auto;
  height: 66px;
  width: 66px;
}
// Breakpoints
$break-desktop: 1500px;
$break-desktopMinus: 1206px;
$break-desktop-tablet: 1200px;
$break-tablet: 1050px;
$break-tablet-boxes: 980px;
$break-boxes: 880px;
$break-phablet: 768px;
$break-midphablet: 620px;
$break-miniphablet: 520px;
$break-phone: 480px;
$break-midphone: 405px;
$break-miniphone: 375px;

// Breakpoints | Específicos
$break-menu-mobile: 'boxes';

// Imports
@import "compass/css3";
@import "compass/utilities";